<template>
  <div class="flex overflow-hidden relative">
    <div class="py-8 pr-7 w-full" :class="addModal === true ? 'mr-72' : ''">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Removal Log</h1>
        <button
          @click="addNew()"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          Add New
        </button>
      </div>
      <div class="" v-if="removals.length !== 0">
        <removal-table
          :allEnquiries="removals"
          :perPage="perPage"
          :total="totalRemoval"
          :currentPage="currentPage"
          @edit="editRemoval"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <add-removal-modal
      @close="
        addModal = false;
        removal = {};
        action = 'Add';
      "
      @created="getAllRemovals()"
      v-if="addModal"
      :newRemoval="removal"
      :action="action"
    />
  </div>
</template>

<script>
import { getRemoval } from "@/services/enquiry";

export default {
  name: "RemovalLog",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
    AddRemovalModal: () => import("@/components/Enquiry/AddRemovalModal.vue"),
    RemovalTable: () => import("@/components/Enquiry/RemovalLogTable.vue"),
  },
  data() {
    return {
      removals: [],
      removal: {},
      removalPerPage: 0,
      totalRemoval: 0,
      currentPage: 0,
      perPage: 0,
      addModal: false,
      action: "Add",
    };
  },
  computed: {
    branch() {
      return this.$store.state.userDetail.branch_id;
    },
  },
  methods: {
    async getAllRemovals() {
      this.addModal = false;
      this.removal = {};
      this.action = "Add";
      try {
        const response = await getRemoval(this.branch);
        this.removals = response.data.removal.data;
        this.removalPerPage = response.data.removal.per_page;
        this.totalRemoval = response.data.removal.total;
        this.currentPage = response.data.removal.current_page;
        this.perPage = response.data.removal.per_page;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
      }
    },
    addNew() {
      this.removal = {};
      this.action = "Add";
      this.addModal = true;
    },
    editRemoval(item) {
      const removal = {
        id: item.id,
        corpse_tag: item.corpse_id,
        removed_by_name_of_driver: item.removed_by_name_of_driver,
        removed_by_phone_number: item.removed_by_phone_number,
        removed_by_ambulance_no: item.removed_by_ambulance_no,
        removed_by_date: item.removed_by_date,
        removal_authorized_by_name: item.removal_authorized_by_name,
        removal_authorized_by_phone_number:
          item.removal_authorized_by_phone_number,
        removal_authorized_by_date: item.removal_authorized_by_date,
        removal_authorized_by_signature: item.removal_authorized_by_signature,
        place_of_removal: item.place_of_removal,
        location_from: item.location_from,
        location_to: item.location_to,
        family_contact_name: item.family_contact_name,
        family_contact_phone_number: item.family_contact_phone_number,
        family_contact_address: item.family_contact_address,
      };
      this.removal = removal;
      this.action = "Edit";
      this.addModal = true;
    },
  },
  mounted() {
    this.getAllRemovals();
  },
};
</script>
