import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = { Authorization: `Bearer ${token}` };

const baseURL = process.env.VUE_APP_BASE_URL;

export function createEnquiry(payload) {
  return axios.post(
    `${baseURL}/enquiry/create`,
    { ...payload },
    { headers: header }
  );
}

export function getEnquiries(page_number = 10) {
  return axios.get(`${baseURL}/enquiry?page_number=${page_number}`, {
    headers: header,
  });
}

export function getEnquiry(id) {
  return axios.get(`${baseURL}/enquiry/${id}`, { headers: header });
}

export function editEnquiry(payload) {
  return axios.put(
    `${baseURL}/enquiry/edit`,
    { ...payload },
    { headers: header }
  );
}

export function deleteEnquiry(id) {
  return axios.delete(`${baseURL}/enquiry/${id}`, { headers: header });
}

export function getRemoval(branch_id) {
  return axios.get(`${baseURL}/removals?branch_id=${branch_id}`, {
    headers: header,
  });
}

export function createRemoval(payload) {
  return axios.post(`${baseURL}/removals`, { ...payload }, { headers: header });
}

export function editRemoval(payload) {
  return axios.post(
    `${baseURL}/removals/edit`,
    { ...payload },
    { headers: header }
  );
}
